import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const InputGroup = lazy(
    () => import(/* webpackChunkName: "input-group" */ '../../../shared/form/input-group')
);
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../../shared/form/select-group')
);
const CheckboxGroup = lazy(
    () => import(/* webpackChunkName: "checkbox-group" */ '../../../shared/form/checkbox-group')
);
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MediaQuery as MQ } from '../../../shared/media-query';
import { getBirthDate, months, days } from '../../../shared/util';

dayjs.extend(customParseFormat);

class Component extends BaseComponent {
    /* eslint-disable complexity */
    constructor(props) {
        super(props);

        if (props.user && props.user.logged_in) {
            props.close();
        } else if (!props.user.email || !props.user.password) {
            props.routeTo('login-signup');
        } else {
            props.trackEvent(['Open', 'SiteInteraction:Signup']);
            const birth_date = dayjs(props.user.birth_date, 'YYYY-MM-DD');
            this.state = {
                name: {
                    value: props.user.birth_name,
                    error: null,
                },
                birthdate: {
                    value: birth_date.isValid() ? birth_date.format('YYYY-MM-DD') : null,
                    error: null,
                },
                birthdateMonth: {
                    value: birth_date.isValid() ? birth_date.format('M') : null,
                    error: null,
                },
                birthdateDay: {
                    value: birth_date.isValid() ? birth_date.format('D') : null,
                    error: null,
                },
                birthdateYear: {
                    value: birth_date.isValid() ? birth_date.format('YYYY') : null,
                    error: null,
                },
                agree: {
                    value: null,
                },
                sms_optin: {
                    value: null,
                },
                newsletter_optin: {
                    value: props.mailListType && props.mailListType == 'Newsletter',
                },
                privacy_policy: {
                    value: props.ppc,
                    error: null,
                },
                button_text: 'ENTER',
                title: 'Let Us Get to Know You',
                subtitle: 'Personalize your experience by telling us about yourself…',
            };

            if (props.product && props.product.type) {
                const product = props.mailListType && props.mailListType == 'Newsletter' ? 'Subscription' : props.product.type; //eslint-disable-line max-len
                this.state.title = `Your ${product} Awaits!`;
                this.state.button_text = `GET MY ${product}!`.toUpperCase();
            }

            this.years = [];
            for (let y = 16; y <= 101; y++) {
                this.years.push(dayjs().subtract(y, 'year').format('YYYY'));
            }
        }
    }
    /* eslint-enable complexity */

    componentDidMount() {
        try {
            document.querySelector('signup-modal input[name="name"]').focus();
        } catch (error) {
            // do nothing, the component needs to reload after the lazyloaded components are ready
        }
    }

    /* eslint-disable max-len */
    render({
        close,
        mailListType,
        user,
    }, {
        agree,
        birthdate,
        birthdateDay,
        birthdateMonth,
        birthdateYear,
        button_text,
        name,
        newsletter_optin,
        subtitle,
        title,
    }) {
        if (user && !user.logged_in && user.email && user.password) {
            return (
                <signup-modal>
                    <dialog-header>
                        <button onClick={close}>×</button>
                        <h1 className='fancy-title has-text-center'>{title}</h1>
                        <p className='has-text-center has-margin-0-bottom'>{subtitle}</p>
                    </dialog-header>
                    <dialog-content>
                        <form autoComplete='off' onSubmit={this.validate}>
                            <row className='is-wrap'>
                                <column className='is-12 is-6-desktop has-padding-10-right-desktop'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            autoFocus
                                            index='1'
                                            type='text'
                                            label='Your Name'
                                            name='name'
                                            value={name.value}
                                            error={name.error}
                                            onInput={this.setFormValues}
                                            maxLength='64'
                                        />
                                    </Suspense>
                                </column>
                                <column className='is-6 is-visible-desktop'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <InputGroup
                                            type='date'
                                            label='Your Birth Date'
                                            name='birthdate'
                                            maxLength='10'
                                            placeholder='MM/DD/YYYY'
                                            value={birthdate.value}
                                            error={birthdate.error}
                                            onInput={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                                <column className='is-12 is-hidden-desktop'>
                                    <label>Your Birth Date</label>
                                </column>
                                <column className='is-6 is-hidden-desktop has-padding-10-right'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <SelectGroup
                                            name='birthdateMonth'
                                            placeholder='Month'
                                            options={months}
                                            value={birthdateMonth.value}
                                            error={birthdateMonth.error}
                                            onChange={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                                <column className='is-3 is-hidden-desktop has-padding-10-right'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <SelectGroup
                                            name='birthdateDay'
                                            placeholder='Day'
                                            options={days}
                                            value={birthdateDay.value}
                                            error={birthdateDay.error}
                                            onChange={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                                <column className='is-3 is-hidden-desktop'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <SelectGroup
                                            name='birthdateYear'
                                            placeholder='Year'
                                            options={this.years}
                                            value={birthdateYear.value}
                                            error={birthdateYear.error}
                                            onChange={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            {this.renderBirthDateError()}
                            <row className='agree_row'>
                                <column className='is-12'>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <CheckboxGroup
                                            name='agree'
                                            value='1'
                                            label='I agree to Tarot.com Terms of use'
                                            checked={agree.value}
                                            onChange={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            <row>
                                <column className={mailListType && mailListType == 'Newsletter' ? 'is-hidden' : 'is-12'}>
                                    <Suspense fallback={<div className='skeleton' />}>
                                        <CheckboxGroup
                                            name='newsletter_optin'
                                            value='1'
                                            label='Get my FREE horoscope emails'
                                            checked={newsletter_optin.value}
                                            onChange={this.setFormValues}
                                        />
                                    </Suspense>
                                </column>
                            </row>
                            {this.renderSMS()}
                            {this.renderPPC()}
                            <row>
                                <column className='is-12 has-text-center has-padding-10-top'>
                                    <button
                                        onClick={this.validate}
                                        disabled={user.submitting}
                                    >
                                        {button_text}
                                    </button>
                                </column>
                            </row>
                            {this.renderTOS()}
                        </form>
                    </dialog-content>
                </signup-modal>
            );
        }

        return null;
    }
    /* eslint-enable max-len */

    renderBirthDateError = () => {
        if (
            this.state.birthdate.error
            &&
            this.state.birthdateMonth.value
            &&
            this.state.birthdateDay.value
            &&
            this.state.birthdateYear.value
        ) {
            return (
                <p className='is-error birthdate-error is-hidden-desktop'>{this.state.birthdate.error}</p>
            );
        }

        return null;
    }

    renderPPC = () => {
        if (!this.props.ppc) {
            return (
                <row>
                    <column className='is-12'>
                        <Suspense fallback={<div className='skeleton' />}>
                            <CheckboxGroup
                                name='privacy_policy'
                                value={this.state.privacy_policy.value}
                                ppcLabel
                                checked={this.state.privacy_policy.value}
                                error={this.state.privacy_policy.error}
                                onChange={this.setFormValues}
                            />
                        </Suspense>
                    </column>
                </row>
            );
        }

        return null;
    }

    renderSMS = () => {
        if (this.props.product.pending) {
            return (
                <row>
                    <column className='is-12'>
                        <Suspense fallback={<div className='skeleton' />}>
                            <CheckboxGroup
                                name='sms_optin'
                                value='1'
                                label='Get 50% OFF your next reading'
                                checked={this.state.sms_optin.value}
                                onChange={this.setFormValues}
                            />
                        </Suspense>
                    </column>
                </row>
            );
        }

        return null;
    }

    renderTOS = () => {
        if (this.props.ppc) {
            /* eslint-disable max-len */
            return (
                <row>
                    <column className='is-12'>
                        <p className='has-text-center has-margin-15-top has-margin-10-bottom has-text-size-14'>
                            Click to view our <a href='/privacy-policy' target='_blank' rel='noopener' onClick={() => this.trackClick('Modal_SignUp:PrivacyPolicy')}>Privacy Policy</a> and <a href='/terms-of-use' target='_blank' rel='noopener' onClick={() => this.trackClick('Modal_SignUp:TermsofUse')}>Terms of Use</a>.
                        </p>
                    </column>
                </row>
            );
            /* eslint-enable max-len */
        }

        return null;
    }

    setFormValues = (e) => {
        let state = {};

        if ((e.type == 'keyup' && e.key == 'Enter') || e.type == 'change' || e.type == 'input') {
            if (['birthdateMonth', 'birthdateDay', 'birthdateYear'].includes(e.target.name)) {
                state = {
                    birthdateMonth: {
                        value: this.state.birthdateMonth.value,
                        error: null,
                    },
                    birthdateDay: {
                        value: this.state.birthdateDay.value,
                        error: null,
                    },
                    birthdateYear: {
                        value: this.state.birthdateYear.value,
                        error: null,
                    },
                };

                state[e.target.name].value = e.target.value;

                state.birthdate = {
                    value: getBirthDate(
                        state.birthdateMonth.value,
                        state.birthdateDay.value,
                        state.birthdateYear.value
                    ),
                    error: null,
                };
            } else if (['privacy_policy', 'newsletter_optin', 'sms_optin', 'agree'].includes(e.target.name)) {
                state[e.target.name] = {
                    value: e.target.checked,
                    error: null,
                };
            } else {
                state[e.target.name] = {
                    value: e.target.value,
                    error: null,
                };
            }

            this.setState(state);
        }
    }

    /* eslint-disable complexity */
    validate = (e) => {
        e.preventDefault();

        const state = {
            name: {
                value: this.state.name.value,
                error: this.validateNameLength(),
            },
            birthdate: {
                value: this.state.birthdate.value,
                error: this.validateBirthdate(),
            },
            birthdateMonth: {
                value: this.state.birthdateMonth.value,
                error: this.validateBirthdate() && !this.state.birthdateMonth.value ? 'Required' : null,
            },
            birthdateDay: {
                value: this.state.birthdateDay.value,
                error: this.validateBirthdate() && !this.state.birthdateDay.value ? 'Required' : null,
            },
            birthdateYear: {
                value: this.state.birthdateYear.value,
                error: this.validateBirthdate() && !this.state.birthdateYear.value ? 'Required' : null,
            },
            privacy_policy: {
                value: this.state.privacy_policy.value,
                error: !this.props.ppc && !this.state.privacy_policy.value ? 'Please accept before continuing' : null,
            },
            agree: {
                value: this.state.agree.value || document.querySelector('input[name="agree"]').checked,
            },
            sms_optin: {
                value: this.state.sms_optin.value ||
                    document.querySelector('input[name="sms_optin"]') &&
                    document.querySelector('input[name="sms_optin"]').checked,
            },
        };

        this.setState(state);

        if (
            state.agree.value
            ||
            document.querySelector('input[name="agree"]').checked
        ) {
            this.props.trackEvent([
                '#signup',
                'Bot:Attempted',
            ]);
        }

        if (
            !state.name.error
            &&
            !state.birthdate.error
            &&
            !state.privacy_policy.error
            &&
            !state.agree.value
            &&
            !document.querySelector('input[name="agree"]').checked
        ) {
            let subscribe_lists = [
                'birthday',
                'content_page',
                'ipe',
                'kca',
                'product_teeup',
                'receipt',
                'tarot_trigger',
                'welcome',
            ];

            if (this.state.newsletter_optin.value) {
                subscribe_lists = subscribe_lists.concat(
                    ['dailyhoroscope', 'dle', 'weekly_love']
                );
            }

            let signup_gateway = '#signup';

            if (this.state.newsletter_optin.value) {
                this.props.trackEvent([
                    'Click',
                    'Modal_SignUp:GetMyHoroscopeEmail',
                ]);
            }

            if (this.props.mailListType && this.props.mailListType == 'Newsletter') {
                signup_gateway = 'newsletter-module';
            } else if (this.props.product && this.props.product.type) {
                if (this.props.product.type == 'Report') {
                    if (this.props.product.description && this.props.product.description.code) { // cospro
                        signup_gateway = this.props.product.description.code;
                    } else if (this.props.product.description && this.props.product.description.slug) { // BPF/L, Numero
                        signup_gateway = this.props.product.description.slug;
                    } else if (this.props.compatSlant) { // compat chooser
                        signup_gateway = `compat-tag-${this.props.compatSlant}`;
                    }
                } else if (this.props.product.type == 'Reading') {
                    if (this.props.product.teeup && this.props.product.teeup.code) { // tarot readings
                        signup_gateway = this.props.product.teeup.code;
                    } else if (this.props.product.description && this.props.product.description.code) { // i ching
                        signup_gateway = this.props.product.description.code;
                    }
                }
            }

            let payload = {
                first_name: state.name.value,
                display_name: state.name.value,
                email: this.props.user.email,
                password: this.props.user.password,
                password_verify: this.props.user.password,
                signup_gateway: signup_gateway,
                signup_bonus: true,
                signup_url: window.location.href,
                account_type_id: 1,
                birth_date: dayjs(state.birthdate.value).format('YYYY-MM-DD'),
                timezone: (new Date().getTimezoneOffset() / 60) * -1,
                subscribe_lists: subscribe_lists,
                privacy_policy: true,
                sms_optin: this.state.sms_optin.value,
            };

            if (this.props.user.gender) {
                payload.gender = this.props.user.gender;
            }

            if (this.props.user.birth_time) {
                payload.birth_time = this.props.user.birth_time;
            }

            if (this.props.user.birth_location) {
                payload.birth_location = this.props.user.birth_location;
            }

            this.props.userSignup(payload);
        }
    }
    /* eslint-enable complexity */

    validateBirthdate = () => {
        if (this.state.birthdate.value) {
            let birthdate = null;

            if (this.state.birthdate.value.match(/[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/)) {
                birthdate = dayjs(this.state.birthdate.value);
            } else if (this.state.birthdate.value.match(/[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/)) {
                birthdate = dayjs(this.state.birthdate.value);
            } else {
                return 'Please enter a valid Birth Date: mm/dd/yyyy';
            }

            const sixteen_years_ago = dayjs().subtract(16, 'year').startOf('day');
            const one_hundred_years_ago = dayjs(`${this.years.slice(-1)[0]}-01-01`);

            if (!birthdate.isValid()) {
                return 'Please enter a valid Birth Date: mm/dd/yyyy';
            } else if (birthdate.isAfter(sixteen_years_ago)) {
                return MQ.isMobile()
                    ? 'You must be at least 16 years of age to signup.'
                    : 'You must be 16 years or older to signup.';
            } else if (birthdate.isBefore(one_hundred_years_ago)) {
                return `Please enter a Birth Date after ${one_hundred_years_ago.format('MM/DD/YYYY')}`;
            }

            return null;
        }

        return 'Please enter your birth date';
    }

    validateNameLength = () => {
        if (!this.state.name.value) {
            return 'Please enter your name.';
        } else if (this.state.name.value.length > 63) {
            return 'Please enter a name 63 characters or less.';
        }

        return null;
    }

    trackClick = (info) => {
        this.props.trackEvent(['Click', info]);
    }
}

const mapStateToProps = (state) => ({
    compatSlant: state['compatibility-chooser'] && state['compatibility-chooser'].slant,
    ppc: state.cookie && state.cookie.PRIVACY_POLICY_CONSENT,
    product: state.product,
    mailListType: state['mail-lists'] && state['mail-lists'].type,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    routeTo: (route) => dispatch({ type: 'route:to', payload: route }),
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
    userSignup: (payload) => dispatch({ type: 'user:signup-api', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
