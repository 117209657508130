import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { MediaQuery as MQ } from '../../shared/media-query';

class Component extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            offer: {
                tid: '9I2LOT8O',
                long_label: '10 Minutes $1.99',
                short_label: '10 Min',
            },
        };

        props.init({
            size: props.size || 1,
            offer: this.state.offer,
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.keen != this.props.keen) {
            return true;
        }

        return false;
    }

    render({ keen, mode }) {
        if (Object.keys(keen).length) {
            return (
                <keen mode={mode}>
                    <row className='is-wrap'>
                        {Object.keys(keen).map(key => this.renderAdvisor(keen[key]))}
                    </row>
                </keen>
            );
        }

        return (<keen {...this.props} />); //eslint-disable-line tarot/no-direct-props-access-in-render
    }

    renderAdvisor = (advisor) => {
        return (
            <column className='is-12 keen'>
                <a
                    href={advisor.link}
                    target='_blank'
                    rel='noopener sponsored noreferrer'
                    onClick={() => this.trackClick(advisor.id)}
                >
                    {this.renderAdvisorContent(advisor)}
                </a>
            </column>
        );
    }

    renderAdvisorContent = (advisor) => {
        if (MQ.isMobile()) {
            return (
                <row className='is-wrap'>
                    <column className='is-6 has-padding-20-left'>
                        <img src={advisor.img} alt={`${advisor.id} Logo`} />
                    </column>
                    <column className='is-4 has-padding-20-left'>
                        <p className='has-margin-5-bottom has-text-size-12'>
                            Exceptional
                        </p>
                        <div className='is-flex'>
                            {[0, 1, 2, 3, 4].map(() => (
                                <img
                                    width='20'
                                    height='auto'
                                    src='https://gfx.tarot.com/images/site/tarot/psychics/star.svg'
                                    alt='Keen Psychic Rating Stars'
                                />
                            ))}
                        </div>
                    </column>
                    <column className='is-2 has-margin-20-top'>
                        <span className='psychic-score'>
                            {advisor.rating}
                        </span>
                    </column>
                    <column className='is-12 has-padding-20-left has-padding-20-right'>
                        <h2 className='is-h4'>
                            {advisor.header}
                        </h2>
                        <ul className='has-margin-20-left has-margin-10-top'>
                            {advisor.bullet_points.map(bullet => (
                                <li>
                                    {bullet}
                                </li>
                            ))}
                        </ul>
                    </column>
                    <column className='is-12 psychic-button'>
                        <a
                            className='button button-purple is-pill'
                        >
                            {advisor.id == 'PsychicSource' ? 'Chat Now' : 'Start Now'}
                        </a>
                    </column>
                </row>
            );
        }

        return (
            <row>
                <column className='is-3 has-padding-15-left'>
                    <img src={advisor.img} alt={`${advisor.id} Logo`} />
                </column>
                <column className='is-5'>
                    <h2 className='is-h4'>
                        {advisor.header}
                    </h2>
                    <ul className='has-margin-10-top'>
                        {advisor.bullet_points.map(bullet => (
                            <li>
                                {bullet}
                            </li>
                        ))}
                    </ul>
                </column>
                <column className='is-2 has-margin-20-top'>
                    <span className='psychic-score'>
                        {advisor.rating}
                    </span>
                    <p className='has-margin-30-top has-margin-5-bottom'>
                        Exceptional
                    </p>
                    <div className='is-flex'>
                        {[0, 1, 2, 3, 4].map(() => (
                            <img
                                width='20'
                                height='auto'
                                src='https://gfx.tarot.com/images/site/tarot/psychics/star.svg'
                                alt='Keen Psychic Rating Stars'
                            />
                        ))}
                    </div>
                </column>
                <column className='is-2 psychic-button'>
                    <a className='button button-purple is-pill'>Start Now</a>
                </column>
            </row>
        );
    }

    trackClick = (advisor_id) => {
        this.props.trackEvent([
            'Click',
            `Psychics_LP:${advisor_id}_Card`,
        ]);
    }
}

const mapStateToProps = (state) => ({
    keen: state.keen,
});

const mapDispatchToProps = (dispatch) => ({
    init: (payload) => dispatch({ type: 'keen:initAdvisors', payload: payload }),
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);